<template>
  <div class="sensitive-words">
    <!--顶部导航  -->
    <div class="top-div">
      <!-- id -->
      <div class="one-line">
        <label for="contactName">Id ：</label>
        <input
          type="text"
          id="contactName"
          v-model.trim="sensitiveId"
          class="text-line"
        >
      </div>
      <!-- 敏感词输入 -->
      <div class="two-line">
        <label>{{ $t('SensitiveWords') }}：</label>
        <input
          type="text"
          id="SensitiveWords"
          v-model.trim="sensitiveWord"
          class="content-line"
        >
      </div>
      <!-- 搜索 -->
      <div class="search-line">
        <el-button
          class="sousuo-line"
          @click="searchSensitiveWords"
        >{{ $t('Search') }}</el-button>
      </div>

      <!-- 添加 -->
      <div class="add-line">
        <el-button
          class="tianjia-line"
          @click="getmessage=true"
          :disabled="right<2"
        >{{ $t('AddSensitiveWords') }}</el-button>
      </div>
    </div>

    <!-- 表格数据 -->
    <el-table
      border
      :data="SensitiveWordList"
      style="width: 99%;margin: 0 auto"
    >
      <el-table-column
        prop="sensitiveId"
        label="id"
        class="table-colum"
        align="center"
      >

      </el-table-column>
      <el-table-column
        prop="sensitiveWord"
        :label="$t('SensitiveWords')"
        align="center"
      >

      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('CreateTime')"
        align="center"
      >

      </el-table-column>
      <el-table-column
        prop="creater"
        :label="$t('Creator')"
        align="center"
      >

      </el-table-column>
      <el-table-column
        prop="operation"
        :label="$t('Operation')"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="deleteSensitiveWords(scope.row.sensitiveId)"
          >{{ $t('Delete') }}</el-button>
        </template>

      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fen-pages">
      <el-pagination
        layout="prev, pager, next"
        background
        :total="SensitiveTotal"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 弹出页面 -->
    <el-dialog
      :visible.sync="getmessage"
      :close-on-click-modal="false"
      center
      append-to-body
      :show-close="false"
      :title="$t('SensitiveWords')"
    >
      <el-form class="addSensitive" label-width="fit-content">
        <el-form-item>
          <el-input
              type="textarea"
              :rows="3"
              resize="none"
              v-model="sesitiveWordContent"
              class="minganci"
              maxlength="40"
              show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rest">{{ $t("Cancle") }}</el-button>
        <el-button type="primary" @click="addSensitiveWord">{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
export default {
  name: "SensitiveManagement",
  components: {},
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      getmessage: false,
      right:data.contentPermission,
      inputContnet: "",
      // 搜索绑定id的属性 搜索后续传值
      sensitiveId: "",
      //搜索绑定敏感词的属性，
      sensitiveWord: "",
      //对话框添加敏感词
      sesitiveWordContent: "",
      // 当前页数
      currentPage: 1,
      //敏感词列表总条数， 总条目数
      SensitiveTotal: 0,
      //显示的页数
      pageSize: 10,
      index: 0,
      // 定义敏感词数据列表
      SensitiveWordList: [],
      // 敏感词传值
      initSensitivePost: {
        chamberId: "",
        currentAdmin: "",
        sensitiveId: "",
        sensitiveWord: "",
      },
    };
  },
  created() {},
  mounted() {
    this.initSensitiveData(1);
  },
  methods: {
    /**
     * 初始化敏感词列表
     */
    initSensitiveData(currentPage) {
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const chamberId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.chamberId;
      const params = {
        chamberId: chamberId,
        currentAdmin: currentAdminId,
        page: currentPage,
      };
      // id  js转换， '',0,NaN,null,undefined转换为false，常用if条件判断
      //id文本框绑定一个值，判断是否输入了值，
      //如果有，就在接口参数中加对应的字段，没有接口参数中就不写
      if (this.sensitiveId) {
        params.sensitiveId = this.sensitiveId;
      }
      //敏感词
      if (this.sensitiveWord) {
        params.sensitiveWord = this.sensitiveWord;
      }
      /**
       * 获取敏感词接口
       */
      api.getSensitiveWordList(params).then((res) => {
        // console.log("阿程测试", res);
        this.SensitiveWordList = res.data.pageList;
        this.SensitiveTotal = res.data.total;
      });
    },
    //分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initSensitiveData(currentPage);
    },

    // 对话框添加敏感词
    addSensitiveWord() {
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
          .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
      };
      // 敏感词内容
      if (this.sesitiveWordContent) {
        params.sensitiveWord = this.sesitiveWordContent;
      } else {
        this.$message.warning(this.$t("EmptySensitive"));
        return false;
      }
      // 调接添加敏感词接口
      api.addSensitiveWord(params).then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('SysBusy'));
        }
        this.$message.success(this.$t('AddSuccess'));
        this.initSensitiveData(1);
        this.getmessage = false;
        // this.sesitiveWordContent = res.data.sensitiveWord;
        this.rest();
      });

      // this.initSensitiveData(1);
      // 重新渲染表格数据
      // this.$forceUpdate();
    },
    // 添加敏感词内容重置
    rest() {
      this.getmessage = false;
      this.sesitiveWordContent = "";
    },

    // 删除功能
    async deleteSensitiveWords(id) {
      // if (confirm(this.$t("ComfirmDelete"))) {
      //   const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
      //     .adminData.id;
      //   const params = {
      //     currentAdmin: currentAdminId,
      //   };
      //   params.sensitiveId = id;
      //   api.deleteSensitiveWord(params).then((res) => {
      //     console.log("res.msg", res.msg);
      //     this.initSensitiveData(1);
      //   });
      // }
      /*const confirmResult = await this.$confirm(
        "删除后该报告将彻底消失，是否确认删除?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((error) => error);
      // 进行判断拿confirmResult看是否等于confirm不能于则是取消
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }*/
      // 发起请求 传参
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.id;
        const params = {
          currentAdmin: currentAdminId,
        };
        params.sensitiveId = id;
        api.deleteSensitiveWord(params).then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t('SysBusy'));
          }
          // 成功给个提示信息
          this.$message.success(this.$t('Deleted'));
          // 刷新页面调用获取表格数据的方法 getUserList()
          // 刷新页面
          this.initSensitiveData(1);
        });
      }
    },
    // 搜索功能
    searchSensitiveWords() {
      // id
      this.sensitiveId
        ? (this.initSensitivePost.sensitiveId = this.sensitiveId)
        : delete this.initSensitivePost.sensitiveId;
      //敏感词内容
      this.sensitiveWord
        ? (this.initSensitivePost.sensitiveWord = this.sensitiveWord)
        : delete this.initSensitivePost.sensitiveWord;
      this.initSensitiveData(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.sensitive-words {
  margin: -10px;
  .top-div {
    border-radius: 5px;
    display: flex;
    font-size: 16px;
    min-height: 40px;
    padding: 5px;
    background-color: #677d95;
    color: white;
    width: 99%;
    margin: 10px;
    input {
      min-width: 200px;
      border: 1px solid white;
      margin: 8px 0;
      float: left;
    }
    div label {
      margin: 8px 0 8px 10px;
      display: block;
      float: left;
    }
    .one-line {
      flex: 1;
      text-align: center;
      margin-top: 10px;
      .text-line {
        width: 210px;
        min-height: 30px;
        font-size: 16px;
        border: 1px solid white;
        outline: none;
      }
    }
    .two-line {
      flex: 1;
      text-align: center;
      margin-top: 10px;
      .content-line {
        width: 200px;
        min-height: 30px;
        font-size: 16px;
        border: 1px solid white;
        outline: none;
      }
    }
    // 搜索
    .search-line {
      flex: 1;
      text-align: center;
      margin-top: 8px;
      .sousuo-line {
        width: 180px;
        min-height: 20px;
        line-height: 20px;
        margin-left: 200px;
        font-size: 16px;
        border: 1px solid white;
        color: white;
        background-color: #677d95;
        &:hover {
          background-color: #9fbee6 !important;
        }
      }
    }
    // 添加
    .add-line {
      flex: 1;
      text-align: center;
      margin-top: 8px;
      .tianjia-line {
        margin-left: 200px;
        width: 180px;
        min-height: 20px;
        line-height: 20px;
        font-size: 16px;
        border: 1px solid white;
        color: white;
        background-color: #677d95;
        &:hover {
          background-color: #9fbee6 !important;
        }
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .export-excel-wrapper {
    float: left;
  }

  .el-table-th {
    background-color: #677d95;
    color: white;
    opacity: 1;
  }
  .table {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .table-border {
    border: 0 solid grey;
    width: 98%;
    margin: 8px auto;
    .text-line {
      border: 1px solid blue;
    }
    .el-button {
      color: #951d1ded;
    }
  }

  .fen-pages {
    margin-top: 10px;
  }
  
  .addSensitive{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}
</style>